
import {defineComponent, onMounted, ref, UnwrapRef, reactive, toRaw} from "vue";
import {
  authorizeActive,
  getAuthState,
  StartUpdate,
  getUniqueCode,
  SysCheckUpdate,
  GetSystemVersion, getSystemKey, authorizeAddActiveOrder, authorizeGetAuthCode, getDevLocation
} from "@/api/api_x2server";
import {message} from 'ant-design-vue';

interface FormState {
  code: string;
}

export default defineComponent({
  setup() {
    const priceValue = ref<number>(0)
    const versionNumber = ref<string>("")
    const AuthState = ref<string>("")
    const initData = ref<any>({})
    const visible = ref<boolean>(false)
    const buyVisible = ref<boolean>(false)
    const confirmLoading = ref<boolean>(false)
    const formState: UnwrapRef<FormState> = reactive({
      code: '',
    });
    const rules = {
      code: [
        {required: true, message: '请输入激活码', trigger: 'blur'},
      ],
    }
    const formRef = ref();
    const codes = ref<string>("")
    const address = ref<string>("")

    onMounted(() => {
      init()
      getAddress()
    })

    const init = async () => {
      const res: any = await getAuthState();
      AuthState.value = res.Msg
      const res2: any = await getSystemKey();
      initData.value = res2
      const res3: any = await getUniqueCode();
      codes.value = res3;
      const res4: any = await GetSystemVersion();
      versionNumber.value = res4;
    }
    const checkUpdate = async () => {
      const res: any = await SysCheckUpdate();

      if (res.Result.toUpperCase() == "OK") {
        const res: any = await StartUpdate();
        if (res.Result.toUpperCase() == "OK") {
          message.success("更新成功！")
        }
      }
    }
    const toRegister = () => {
      visible.value = true
    }
    const closeModal = () => {
      formRef.value.resetFields()
      visible.value = false;
    };
    const handleOk = () => {
      formRef.value.validate().then(async () => {
        confirmLoading.value = true
        const res: any = await authorizeActive(toRaw(formState));
        if (res.Result.toUpperCase() == "OK") {
          message.success(res.Msg)
          closeModal()
          init()
        }
        confirmLoading.value = false
      })
    };
    const buyKey = async () => {
      const res: any = await authorizeAddActiveOrder({
        Type: priceValue.value
      });
      if (res.success == true) {
        buyVisible.value = false
        priceValue.value = 0
        message.success("购买成功！")
      }else{
        message.error("购买出错了！")
      }
    }
    const getKey = async () => {
      const res: any = await authorizeGetAuthCode();
      if (res.success == true) {
        formState.code = res.data;
        message.success("获取成功！")
      }else{
        message.error("请先购买激活码！")
      }
    }
    const getAddress = async () => {
      const res: any = await getDevLocation();
      address.value = res.content.address
    }
    return {
      priceValue,
      AuthState,
      versionNumber,
      initData,
      visible,
      buyVisible,
      confirmLoading,
      formState,
      rules,
      formRef,
      codes,
      address,
      checkUpdate,
      toRegister,
      closeModal,
      handleOk,
      buyKey,
      getKey,
    }
  }
})
