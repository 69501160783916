
import {defineComponent, onMounted,ref,UnwrapRef,reactive,createVNode} from "vue";
import type { TableProps } from 'ant-design-vue';
import {addToken, getTokenList,deleteToken} from "@/api/api_x2server";
import {Modal, message} from 'ant-design-vue';
import {
  ExclamationCircleOutlined
} from '@ant-design/icons-vue';

const columns = [
  {
    title: 'Token字符串',
    dataIndex: 'Token',
  },
  {
    title: '是否有效',
    key:'isVaild',
  },
  {
    key: 'userInfo',
    title: '用户信息',
  },
  {
    key: 'des',
    title: '描述信息',
  },
  {
    title: '访问等级',
    key:'level',
  }, {
    key:'createTime',
    title: '创建日期'
  }, {
    key:'expirationDate',
    title: '到期日期'
  },
  {
    title: '操作',
    align: 'center',
    width:'200px',
    key:'operation'
  }
]

interface FormState {
  User: string;
  Des: string;
  Level: number;
  Exp: string;
}
const LevelOption = [{
  value:0,
  label:"系统管理"
},{
  value:1,
  label:"可读可写"
},{
  value:2,
  label:"只读"
}]
export default defineComponent({
  setup() {
    const formRef = ref();
    const loading = ref<boolean>(false)
    const dataSource = ref<any>([])
    const pagination = ref<any>({
      total: 0,
      current: 1,
      pageSize: 10,
    })
    const modalTitle = ref<string>("");
    const visible = ref<boolean>(false);
    const confirmLoading = ref<boolean>(false);
    const formState: UnwrapRef<FormState> = reactive({
      User: '',
      Des: '',
      Level: 2,
      Exp: '',
    });

    onMounted(() => {
      getList()
    })

    const getList = async () => {
      const res: any = await getTokenList({
        pos:pagination.value?.current,
        count:pagination.value?.pageSize
      })
      dataSource.value = res.Items;
      pagination.value!.total = res.TotalCount
    }
    const handleTableChange: TableProps['onChange'] = (
        pag: any,
    ) => {
      pagination.value = pag
      getList()
    };
    const toAddToken = () => {
      modalTitle.value = "新增Token"
      visible.value = true
    };
    const handleOk = () => {
      formRef.value
          .validate()
          .then(async () => {
            confirmLoading.value = true;
            let res: any = await addToken(formState)
            confirmLoading.value = false;
            if(res.Token){
              message.success("添加成功！");
              getList();
              closeModal();
            }
          })
    }
    const closeModal = () => {
      formRef.value.resetFields()
      visible.value = false;
    };
    const toDelete = (token: number) => {
      Modal.confirm({
        title: () => '是否删除该Token?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => '是',
        okType: 'danger',
        cancelText: () => '否',
        async onOk() {
          const res: any = await deleteToken({TK:token})
          if (res.Result.toUpperCase() == "OK") {
            message.success("删除成功！")
            getList();
          }
        },
      });
    }
    return {
      formRef,
      loading,
      dataSource,
      pagination,
      columns,
      visible,
      modalTitle,
      confirmLoading,
      formState,
      handleTableChange,
      toAddToken,
      LevelOption,
      handleOk,
      closeModal,
      toDelete,
    }
  }
})
