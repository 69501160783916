
import {defineComponent, ref} from "vue";
import {getLogApi, getLogFileList, getLogFileStream} from "@/api/api_x2server";

export default defineComponent({
  setup() {
    const rowNumber = ref<string>()
    const date = ref<string>()
    const selectLog = ref<string>()
    const logList = ref<any>()
    const logData = ref<string>()

    const dateChange = async () => {
      selectLog.value = ""
      logList.value = []
      if(date.value == null){
        return
      }
      const res: any = await getLogFileList({
        DateTimePattern: date.value
      });
      logList.value = res;
    }

    const getLog = async () => {
      const res: any = await getLogApi({count: rowNumber.value});
      logData.value = res;
    }

    const download = async () => {
      const res: any = await getLogFileStream({LogFile: selectLog.value});
      let url = window.URL.createObjectURL(new Blob([res]))
      let a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.setAttribute('download',selectLog.value as string)
      document.body.appendChild(a)
      a.click() //执行下载
      window.URL.revokeObjectURL(a.href) //释放url
      document.body.removeChild(a)
    }

    return {
      rowNumber,
      date,
      selectLog,
      logList,
      dateChange,
      download,
      getLog,
      logData
    }
  }
})
