
import {defineComponent, ref} from "vue";
import about from "@/views/systemInfo/modules/about.vue";
import systemModule from "@/views/systemInfo/modules/systemModule.vue";
import tokenView from "@/views/systemInfo/modules/tokenView.vue";
import logView from "@/views/systemInfo/modules/logView.vue";
import dashboard from "@/views/systemInfo/modules/dashboard.vue";
import historyData from "@/views/systemInfo/modules/historyData.vue";

export default defineComponent({
  setup() {
    return {
      activeKey: ref<string>("1"),
    }
  },
  components: {
    about,
    systemModule,
    tokenView,
    logView,
    dashboard,
    historyData,
  }
})
