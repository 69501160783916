
import * as echarts from 'echarts'
import {defineComponent, ref, onMounted, onBeforeUnmount} from "vue";
import {getPerformance} from "@/api/api_x2server";

export default defineComponent({
  setup() {
    let spinning = ref<boolean>(false);
    let indexChart = ref();
    let indexChart2 = ref();
    let indexChart3 = ref();
    let indexChart4 = ref();
    const runTime = ref<number>();
    let chart_1: any;
    let chart_2: any;
    let chart_3: any;
    let chart_4: any;
    let chart3Data: any = {
      xData: [],
      yData: [],
    }
    let chart4Data: any = {
      xData: [],
      yData: [],
    }
    let timer: number;
    onMounted(() => {
      init()
    })
    const init = async () => {
      spinning.value = true
      const res: any = await getPerformance();
      chart_1 = echarts.init(indexChart.value)
      chart_2 = echarts.init(indexChart2.value)
      chart_3 = echarts.init(indexChart3.value)
      chart_4 = echarts.init(indexChart4.value)

      await setCharts3and4Data(res)
      initCharts(res)
      timer = setInterval(function () {
        updateCharts()
      }, 2000);
      spinning.value = false
    }

    onBeforeUnmount(()=>{
      //销毁前清除定时任务
      clearInterval(timer)
    })

    const updateCharts = async () => {
      const res: any = await getPerformance();
      await setCharts3and4Data(res)
      initCharts(res)
    }
    const setCharts3and4Data = (data: any) => {
      let time = new Date();
      let h = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
      let mm = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
      let s = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
      let xDate = h + ':' + mm + ':' + s;
      if (chart3Data.xData.length > 20) {
        chart3Data.xData.splice(0, 1)
        chart3Data.yData.splice(0, 1)
      }
      if (chart4Data.xData.length > 20) {
        chart4Data.xData.splice(0, 1)
        chart4Data.yData.splice(0, 1)
      }
      chart3Data.xData.push(xDate)
      // data.CpuUsage = (Math.random() * 100).toFixed(2);
      chart3Data.yData.push(data.CpuUsage)
      chart4Data.xData.push(xDate)
      // data.RamUsage = (Math.random() * 100).toFixed(2);
      chart4Data.yData.push(data.RamUsage)
    }

    const initCharts = (data: any) => {
      runTime.value = data.RunTime;
      // barChartOptions 配置项，推荐放在外部引入。
      chart_1.setOption({
        series: [
          {
            radius: '80%',
            name: 'Pressure',
            type: 'gauge',
            detail: {
              offsetCenter: [0, "80"],
              formatter: '使用率：{value}%',
              fontSize: "15"
            },
            data: [
              {
                title: {
                  offsetCenter: [0, "40"],
                  fontSize: "18"
                },
                value: data.CpuUsage,
                name: 'CPU'
              }
            ]
          }
        ]
      });
      chart_2.setOption({
        series: [
          {
            radius: '80%',
            center: ["50%", "40%"],
            name: 'Pressure',
            type: 'gauge',
            detail: {
              offsetCenter: [0, "105"],
              formatter: '总内存：' + data.TotalRam
                  + '\n可用：' + data.AvailableRAM
                  + '\n使用率：{value}%',
              fontSize: "15"
            },
            data: [
              {
                title: {
                  offsetCenter: [0, "40"],
                  fontSize: "18"
                },
                value: data.RamUsage,
                name: '内存'
              }
            ]
          }
        ]
      });
      chart_3.setOption({
        grid: {
          top: '5%',
          bottom: '18%'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: chart3Data.xData
        },
        yAxis: {
          type: 'value',
          max: 100,
          min: 0,
          axisLabel: {
            formatter: '{value}%'
          }
        },
        series: [
          {
            data: chart3Data.yData,
            type: 'line'
          }
        ]
      });
      chart_4.setOption({
        grid: {
          top: '10%',
          bottom: '15%'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: chart4Data.xData
        },
        yAxis: {
          type: 'value',
          max: 100,
          min: 0,
          axisLabel: {
            formatter: '{value}%'
          }
        },
        series: [
          {
            data: chart4Data.yData,
            type: 'line'
          }
        ]
      });
      // window.onresize = function () {
      //   //自适应大小, 不用的话不会自适应大小。
      //   barChart.resize();
      // };
    }


    return {
      spinning,
      indexChart,
      indexChart2,
      indexChart3,
      indexChart4,
      runTime,
    }
  }
})
