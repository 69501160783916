import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "indexChart",
  style: { width: '100%', height: '250px' }
}
const _hoisted_2 = {
  ref: "indexChart2",
  style: { width: '100%', height: '250px' }
}
const _hoisted_3 = {
  ref: "indexChart3",
  style: { width: '100%', height: '250px' }
}
const _hoisted_4 = {
  ref: "indexChart4",
  style: { width: '100%', height: '250px' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createBlock(_component_a_spin, { spinning: _ctx.spinning }, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, { span: 24 }, {
            default: _withCtx(() => [
              _createTextVNode("运行时间：" + _toDisplayString(_ctx.runTime), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 6 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, null, 512),
              _createElementVNode("div", _hoisted_2, null, 512)
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 18 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, null, 512),
              _createElementVNode("div", _hoisted_4, null, 512)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["spinning"]))
}