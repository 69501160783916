
import {defineComponent, onMounted,ref,createVNode} from "vue";
import {getModuleTree,getModuleInfo} from "@/api/api_x2server";
import { TreeDataItem } from 'ant-design-vue/es/tree/Tree';
import type { TreeProps } from 'ant-design-vue';
import {
  CodepenOutlined
} from '@ant-design/icons-vue';

export default defineComponent({
  setup() {
    const c_height = ref<number>()
    const moduleInfo = ref<any>([])
    const treeData = ref<TreeDataItem[]>([]);
    const sKeys = ref<(string | number)[]>([''])
    const myLeafIcon = createVNode(CodepenOutlined)

    onMounted(() => {
      let a : any = document.getElementsByClassName("location")[0];
      c_height.value = a.parentNode.parentNode.parentNode.clientHeight - 50;
      getTree()
    })

    const getTree = async () => {
      const res: any = await getModuleTree();
      treeData.value = res.map((e: any)=>{
        let tree: any = {
          title:e.Name,
          key:e.ID
        }
        if(e.HasChild === false){
          tree.isLeaf = true
          tree.switcherIcon = myLeafIcon;
        }
        return tree
      })
    }
    const onLoadData = async (treeNode: any) => {
      let parent:string;
      if(treeNode.dataRef.parent){
        parent = treeNode.dataRef.parent + "/" + treeNode.dataRef.title
      }else{
        parent = treeNode.dataRef.title
      }
      const res: any = await getModuleTree({parent});
      treeNode.dataRef.children = res.map((e: any)=>{
        let tree: any = {
          title:e.Name,
          key:e.ID,
          parent:parent
        }
        if(e.HasChild === false){
          tree.isLeaf = true
          tree.switcherIcon = myLeafIcon;
        }
        return tree
      })
      treeData.value = [...treeData.value];
    }
    const onSelect: TreeProps['onSelect'] = async (selectedKeys: (string | number)[], info: any) => {
      if(selectedKeys.length == 0){
        return
      }
      sKeys.value = selectedKeys
      let path;
      if(info.node.dataRef.parent){
        path = info.node.dataRef.parent + "/" + info.node.dataRef.title
      }else{
        path = info.node.dataRef.title
      }
      const res: any = await getModuleInfo({path});
      moduleInfo.value = res.map((e: any)=>{
        return e.Name + ": " + e.Value
      })
    };

    return {
      c_height,
      treeData,
      moduleInfo,
      sKeys,
      onLoadData,
      onSelect,
    }
  }
})
