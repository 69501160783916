import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logView = _resolveComponent("logView")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_historyData = _resolveComponent("historyData")!
  const _component_dashboard = _resolveComponent("dashboard")!
  const _component_tokenView = _resolveComponent("tokenView")!
  const _component_systemModule = _resolveComponent("systemModule")!
  const _component_about = _resolveComponent("about")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createBlock(_component_a_tabs, {
    activeKey: _ctx.activeKey,
    "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tab_pane, {
        key: "1",
        tab: "日志"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_logView)
        ]),
        _: 1
      }),
      _createVNode(_component_a_tab_pane, {
        key: "2",
        tab: "历史数据"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_historyData)
        ]),
        _: 1
      }),
      _createVNode(_component_a_tab_pane, {
        key: "3",
        tab: "仪表盘"
      }, {
        default: _withCtx(() => [
          (_ctx.activeKey == 3)
            ? (_openBlock(), _createBlock(_component_dashboard, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_a_tab_pane, {
        key: "4",
        tab: "Token管理"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tokenView)
        ]),
        _: 1
      }),
      _createVNode(_component_a_tab_pane, {
        key: "5",
        tab: "系统模型"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_systemModule)
        ]),
        _: 1
      }),
      _createVNode(_component_a_tab_pane, {
        key: "6",
        tab: "关于"
      }, {
        default: _withCtx(() => [
          (_ctx.activeKey == 6)
            ? (_openBlock(), _createBlock(_component_about, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["activeKey"]))
}