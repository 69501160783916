import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_directory_tree = _resolveComponent("a-directory-tree")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, { class: "location" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, { span: 10 }, {
        default: _withCtx(() => [
          _createVNode(_component_a_directory_tree, {
            height: _ctx.c_height,
            "load-data": _ctx.onLoadData,
            "tree-data": _ctx.treeData,
            selectedKeys: _ctx.sKeys,
            expandAction: "dblclick",
            showLine: true,
            showIcon: false,
            onSelect: _ctx.onSelect
          }, null, 8, ["height", "load-data", "tree-data", "selectedKeys", "onSelect"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, {
        span: 14,
        style: {"padding":"0 30px","line-height":"35px"}
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moduleInfo, (item, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(item), 1))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}