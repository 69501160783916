
import {defineComponent, onMounted, ref} from "vue";
import {getHistoryFileList,getHistory} from "@/api/api_x2server";
import type { TableProps } from 'ant-design-vue';
import type { Dayjs } from 'dayjs';
type RangeValue = [Dayjs, Dayjs];

const columns = [
  {
    title: '设备ID',
    dataIndex: 'DevID',
  },
  {
    title: '设备名',
    dataIndex: 'DevName',
  },
  {
    title: '标签名',
    dataIndex: 'TagName',
  },
  {
    title: '值',
    dataIndex: 'Value',
  },
  {
    title: '创建时间',
    dataIndex: 'CreateTime',
  }
]
export default defineComponent({
  setup() {
    const date = ref<RangeValue>()
    const devName = ref<string>()
    const tagName = ref<string>()
    const selectFile = ref<string>()
    const fileList = ref<any>()
    const dataSource = ref<any>()
    const pagination = ref<any>({
      total: 0,
      current: 1,
      pageSize: 10,
    })
    const loading = ref<boolean>(false)

    onMounted(async ()=>{
      const res: any = await getHistoryFileList();
      fileList.value = res;
    })

    const handleTableChange: TableProps['onChange'] = (
      pag: any,
    ) => {
      pagination.value = pag
      getList()
    };
    const getList = async () => {
      let search: any = {
        page:pagination.value?.current,
        size:pagination.value?.pageSize,
        DBFile:selectFile.value,
        devName:devName.value,
        tagName:tagName.value,
      }
      if(date.value){
        search.startTime = date.value[0]
        search.endTime = date.value[1]
      }
      const res: any = await getHistory(search)
      dataSource.value = res.Items;
      pagination.value!.total = res.TotalCount
    }
    return {
      date,
      devName,
      tagName,
      selectFile,
      fileList,
      columns,
      dataSource,
      pagination,
      loading,
      handleTableChange,
      getList
    }
  }
})
